var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3",staticStyle:{"background-color":"var(--v-white-base)"}},[_c('div',{staticClass:"row-format align-center"},[_c('div',[_vm._v("Time worked events")]),_c('v-icon',{staticClass:"ml-auto",on:{"click":_vm.handleClose}},[_vm._v("$close")])],1),(_vm.isReady)?_c('v-data-table',{key:_vm.refreshKey,staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.eventList,"hide-default-footer":true,"sort-by":"timerStart","sort-desc":true,"fixed-header":true,"height":((_vm.eventList.length < 5 ? _vm.eventList.length * 75 + 50 : 500) + "px"),"items-per-page":-1},on:{"click:row":function($event){return _vm.editTimerEvent($event)}},scopedSlots:_vm._u([{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"brand-semilight font-12",domProps:{"innerHTML":_vm._s(_vm.getStatus(item))}})]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [(item.client)?_c('client-avatar',{attrs:{"small":"","client":item.client}}):_vm._e()]}},{key:"item.timerStartStop",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(_vm.DateTime.fromISO(item.timerStart).toFormat('t').replace(' ', '').toLowerCase())+"-"+_vm._s(_vm.DateTime.fromISO(item.timerEnd).toFormat('t').replace(' ', '').toLowerCase()))])]}},{key:"item.timerStart",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(_vm.DateTime.fromISO(item.timerStart).toFormat('DD')))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(_vm.formatSeconds(item.duration)))])]}},{key:"item.projectName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(item.projectName ? item.projectName : ' -- '))])]}},{key:"item.deliverableName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(item.deliverableName ? item.deliverableName : ' -- '))])]}},{key:"header.action",fn:function(ref){return [_c('div',{staticClass:"row-format"},[_c('v-icon',{staticClass:"ml-auto mr-1 pointer",attrs:{"size":"20"},on:{"click":function($event){return _vm.downloadReport()}}},[_vm._v("$download")])],1)]}},{key:"item.action",fn:function(ref){return [_c('div',{staticClass:"row-format"},[_c('v-icon',{staticClass:"ml-auto mr-1 pointer",attrs:{"size":"20"}},[_vm._v("$moreHorizontal")])],1)]}}],null,false,3748041440)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }