import { render, staticRenderFns } from "./CustomFieldsEditor.vue?vue&type=template&id=41d861c6&scoped=true&"
import script from "./CustomFieldsEditor.vue?vue&type=script&lang=js&"
export * from "./CustomFieldsEditor.vue?vue&type=script&lang=js&"
import style0 from "./CustomFieldsEditor.vue?vue&type=style&index=0&id=41d861c6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41d861c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VIcon,VMenu,VSwitch})
