var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"var(--v-white-base)"}},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.processedEvents,"hide-default-footer":true,"sort-by":"timerStart","sort-desc":true,"items-per-page":-1},on:{"click:row":function($event){return _vm.$emit('edit-timer', $event)}},scopedSlots:_vm._u([{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"brand-semilight font-12"},[(item.invoiceId)?_c('div',{staticClass:"invoice-status",staticStyle:{"--color":"var(--v-success)","--background":"var(--v-success_10-base)"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open-invoice', {clientId: item.clientId, invoiceId: item.invoiceId})}}},[_c('div',{staticClass:"default-label"},[_vm._v("Billed")]),_c('div',{staticClass:"hover-label"},[_vm._v(_vm._s(item.invoiceNumber))])]):_c('div',{staticClass:"pointer",domProps:{"innerHTML":_vm._s(_vm.getStatus(item))}})])]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('assigned-user',{attrs:{"show-name":false,"assigned-to":item.userId}})]}},{key:"item.timerStart",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(_vm.DateTime.fromISO(item.timerStart).toFormat('DD')))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight mr-5"},[_vm._v(_vm._s(_vm.formatSeconds(item.duration)))])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.client)?_c('client-avatar',{attrs:{"client":item.client}}):_c('div',[_vm._v("--")])]}},{key:"item.projectName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(item.projectName ? item.projectName : ' -- '))])]}},{key:"item.deliverableOrNote",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"brand-semilight",staticStyle:{"max-width":"250px"}},[_c('span',{staticStyle:{"word-break":"break-all"},domProps:{"innerHTML":_vm._s(item.deliverableOrNote ? item.deliverableOrNote : ' -- ')}})])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({content:'Duplicate time entry'}),expression:"{content:'Duplicate time entry'}"}],staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('duplicate',item)}}},[_vm._v("content_copy")])]}},{key:"header.action",fn:function(ref){return [_c('div',{staticClass:"row-format ml-auto mr-1",staticStyle:{"justify-content":"flex-end"}},[(_vm.showDownload)?_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"size":"20"}},on),[_vm._v("$download")])]}}],null,true)},[_c('div',{staticClass:"more-menu"},[_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.downloadReport('XLSX')}}},[_vm._v("Excel file")]),_c('div',{staticClass:"more-menu-item",on:{"click":function($event){return _vm.downloadReport('PDF')}}},[_vm._v("PDF file")])])]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }