var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3",staticStyle:{"background-color":"var(--v-white-base)","width":"1000px"}},[_c('div',{staticClass:"row-format align-center"},[_vm._m(0),_c('v-icon',{staticClass:"ml-auto",on:{"click":_vm.handleClose}},[_vm._v("$close")])],1),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.payments,"sort-by":"datePaid","sort-desc":true,"items-per-page":10,"hide-default-footer":_vm.payments.length <= 10},on:{"click:row":function($event){return _vm.openInvoice($event)}},scopedSlots:_vm._u([{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center"},[(item.client)?_c('client-avatar',{attrs:{"small":"","client":item.client}}):_vm._e(),_c('div',[_vm._v(_vm._s(item.client.name))])],1)]}},{key:"item.datePaid",fn:function(ref){
var item = ref.item;
return [(item.datePaid)?_c('span',{staticClass:"brand-semilight",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.DateTime.fromISO(item.datePaid).toFormat('DD')))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('div',{staticClass:"brand-semilight"},[_vm._v(" "+_vm._s(_vm.$formatters.dollars(item.amount ? item.amount : 0, true, true, item.currency))+" ")]):_vm._e()]}},{key:"item.invoiceNumberFormatted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(item.invoiceNumberFormatted))])]}},{key:"item.paymentProvider",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"brand-semilight"},[_vm._v(_vm._s(item.paymentProvider))])]}},{key:"item.pending",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-format align-center"},[_c('div',[_vm._v(_vm._s(item.pending ? 'ACH Pending' : 'Cleared'))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","color":item.pending ? 'warning' : 'success'}},[_vm._v(_vm._s(item.pending ? '$alert' : '$success'))]),(item.integrationKeys.quickbooksId)?_c('v-icon',{staticClass:"ml-2",attrs:{"size":"20"}},[_vm._v("$quickbooks")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No payments found. ")]},proxy:true}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row-format align-center"},[_c('div',[_vm._v("Payments")])])}]

export { render, staticRenderFns }