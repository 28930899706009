import { render, staticRenderFns } from "./FormatModal.vue?vue&type=template&id=973ff186&scoped=true&"
import script from "./FormatModal.vue?vue&type=script&lang=js&"
export * from "./FormatModal.vue?vue&type=script&lang=js&"
import style0 from "./FormatModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./FormatModal.vue?vue&type=style&index=1&id=973ff186&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "973ff186",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VIcon,VSelect})
