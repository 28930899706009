import { render, staticRenderFns } from "./FileAttachmentsConfig.vue?vue&type=template&id=40e8945b&scoped=true&"
import script from "./FileAttachmentsConfig.vue?vue&type=script&lang=js&"
export * from "./FileAttachmentsConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e8945b",
  null
  
)

export default component.exports