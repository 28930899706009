import { render, staticRenderFns } from "./CustomSelect.vue?vue&type=template&id=2055fc55&scoped=true&"
import script from "./CustomSelect.vue?vue&type=script&lang=js&"
export * from "./CustomSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2055fc55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VAutocomplete,VMenu})
