import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=1b77cfc8&scoped=true&"
import script from "./Logo.vue?vue&type=script&lang=js&"
export * from "./Logo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b77cfc8",
  null
  
)

export default component.exports