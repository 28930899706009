import { render, staticRenderFns } from "./LinkedTicket.vue?vue&type=template&id=ec8ae412&scoped=true&"
import script from "./LinkedTicket.vue?vue&type=script&lang=js&"
export * from "./LinkedTicket.vue?vue&type=script&lang=js&"
import style0 from "./LinkedTicket.vue?vue&type=style&index=0&id=ec8ae412&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8ae412",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VMenu})
