import { render, staticRenderFns } from "./DividerConfig.vue?vue&type=template&id=3444602c&scoped=true&"
import script from "./DividerConfig.vue?vue&type=script&lang=js&"
export * from "./DividerConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3444602c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VSlider})
